import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";

const useStyles = makeStyles((theme, props) => ({
	modal: {
		backgroundColor: "#2e2b84",
		width: "100%",
		height: 90,
		position: "fixed",
		bottom: 0,
		zIndex: 9999,
		alignItems: "center",
	},
	paper: {
		padding: 20,
		width: "100%",
		color: "#FFF",
	},
}));

export default function CookieModal(props) {
	const classes = useStyles();

	const [open, setOpen] = useState(true);

	const handleClose = () => {
		setOpen(false);
		localStorage.setItem("CookieModal", "Open");
	};

	function checkIfHasOpened() {
		localStorage.getItem("CookieModal") === "Open"
			? setOpen(false)
			: setOpen(true);
	}

	useEffect(() => {
		checkIfHasOpened();
	});

	return (
		<div
			id={"cookie-modal-parent"}
			className={classes.modal}
			style={{
				display: open === false ? "none" : "flex",
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<header className={"cookie-header"}>
						<h3>Uso de cookies</h3>
						<span
							onClick={() => handleClose()}
							className={"cookie-header-text"}
						>
							X
						</span>
					</header>
					<div className={"cookie-div"}>
						Este site usa cookies, incluindo cookies de analytics,
						cookies de perfil próprio e de terceiros para enviar
						anúncios personalizados de acordo com suas preferências
						pessoais. Se você deseja obter mais informações,
						consulte nossa política de cookies no link.{" "}
						<a
							className="link-cookie-modal"
							href={`${process.env.REACT_APP_API_URL_DEFAUT}/#/politica-de-cookies`}
						>
							Política de cookies
						</a>
						.
					</div>
				</div>
			</Fade>
		</div>
	);
}
