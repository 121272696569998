import styled from "styled-components";

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 0 45px;
	border-radius: 8px;

	h1 {
		padding: 0 20px;
	}

	.multi-input {
		display: flex !important;

		div {
			width: 50%;
		}
	}

	.label-container {
		display: grid;
	}

	.input-width {
		width: 90% !important;
	}

	button {
		span {
			display: flex;
			align-items: center;
		}
	}

	footer {
		position: fixed;
		bottom: 8%;
		left: 0;
		width: 100%;
	}
`;

export const BoxContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
	}
`;

export const Box = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 20px;
	border-radius: 2px;
	position: relative;
	cursor: pointer;

	min-height: 40vh;

	background-color: #ffffff;
	overflow: hidden;

	flex: 1;

	.box-bg {
		position: absolute;
		inset: 0;
		z-index: 1;
	}

	.box-bg-img {
		position: absolute;
		inset: 0;

		width: 100%;
		height: 100%;
		object-fit: cover;

		margin: 0;
	}

	&:nth-child(1) {
		.box-bg {
			background-color: #fe850470;
		}
	}

	&:nth-child(2) {
		.box-bg {
			background-color: #1e6bb170;
		}
	}

	&:nth-child(3) {
		.box-bg {
			background-color: #f86dfc70;
		}
	}

	&:nth-child(4) {
		.box-bg {
			background-color: #3aa64c70;
		}
	}

	.box-content {
		z-index: 2;

		.box-title {
			font-size: 1.75rem !important;
			font-weight: bold;
			line-height: normal;
			margin: 0;
			text-align: center;

			text-transform: uppercase;

			color: #ffffff;
		}
	}

	.animated-content {
		opacity: 0;

		max-height: 90%;

		display: flex;

		padding: 40px;
		margin: 10px;
		border-radius: 30px;
		position: absolute;
		z-index: 3;
		overflow: auto;

		ul {
			margin: 0 0 0 20px;
			padding: 0;

			li {
				color: #ffffff;
				padding: 0.125rem;
				font-weight: 600;
			}
		}

		transform: translateX(100%);
		transition: all 0.5s ease-in-out;
	}

	&:hover {
		.animated-content {
			opacity: 1;
			transform: translateX(0);
		}
	}
`;

export const CustomList = styled.ul``;

export const CustomListItem = styled.li`
	gap: 0.5rem;
	display: flex;
	align-items: center;

	flex: 0;

	& img {
		margin: 0.25rem 0 !important;
		width: 54px;
		height: 54px;
		object-fit: contain;
	}
`;
