import React, { useEffect, useState } from "react";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Footer from "../../components/Footer/Footer";

// Images
import LogoOutline from "../../assets/images/logo-outline.png";
import Association from "../../assets/images/home/association.png";
import Comments from "../../assets/images/etapa7.svg";
import Brain from "../../assets/images/home/brain.png";
import Infrastructure from "../../assets/images/home/infrastructure.png";
import Growth from "../../assets/images/home/growth.png";
import Rated from "../../assets/images/home/rated.png";
import Rapidez from "../../assets/images/home/rapidez.png";
import Delivery from "../../assets/images/home/prazo-de-entrega.png";
import Training from "../../assets/images/home/treinamento.png";
import MontadorFerramenta from "../../assets/images/etapa4.svg";
import Graph from "../../assets/images/graph.svg";
import Cellphone from "../../assets/images/home/cellphone.svg";
import Group from "../../assets/images/home/group.svg";

import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";

import { publicArea } from "../../../Routes.js";

import {
	CarouselProvider,
	Slider,
	Slide,
	ButtonBack,
	ButtonNext,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import Monta from "../../assets/images/monta.png";
import Instala from "../../assets/images/instala.png";
import Repara from "../../assets/images/repara.png";
import Conserva from "../../assets/images/conserva.png";

import MontaIcon from "../../assets/images/home/monta-icon.png";
import InstalaIcon from "../../assets/images/home/instala-icon.png";
import ReparaIcon from "../../assets/images/home/repara-icon.png";
import ConservaIcon from "../../assets/images/home/conserva-icon.png";

import * as S from "./Styles";

function Second(props) {
	// Similar ao componentDidMount e componentDidUpdate:
	useEffect(() => {
		publicArea();
		window.addEventListener("resize", resize);
		resize();
	}, []);

	const [qtdCarrossel, setQtdCarrossel] = useState(5);

	return (
		<section className="home-container about-page">
			<div className="huge-content">
				<div
					id={"who-we-are"}
					className="home-block padding-top-30 max-size-home content-item paragraph-item"
				>
					<div className={"home-block-center section-one-paragraph"}>
						<h2 className={"home-title"}>Quem Somos</h2>
						<p>
							MMS - Muito Mais Serviços: Atualmente, é o{" "}
							<strong>
								maior player deste segmento de mercado
							</strong>
							, sendo a única com capacidade e garantia de
							qualidade na realização de serviços em todo o
							território nacional. Através de Tecnologia de Ponta,
							Processos Robustos e Rede de Parceiros altamente
							Qualificados e Capacitados, a MMS é uma plataforma
							que intermedeia a demanda de serviços B2BSC em todo
							Brasil, oferecendo governança e gestão de ponta a
							ponta nos segmentos:
						</p>
						<ul>
							<S.CustomListItem>
								<img src={MontaIcon} aria-hidden alt="" />{" "}
								<span>Monta</span>
							</S.CustomListItem>
							<S.CustomListItem>
								<img src={InstalaIcon} aria-hidden alt="" />{" "}
								<span>Instala</span>
							</S.CustomListItem>
							<S.CustomListItem>
								<img src={ReparaIcon} aria-hidden alt="" />{" "}
								<span>Repara</span>
							</S.CustomListItem>
							<S.CustomListItem>
								<img src={ConservaIcon} aria-hidden alt="" />{" "}
								<span>Conserva</span>
							</S.CustomListItem>
						</ul>
					</div>
					<div className={"home-block-center section-one-image"}>
						<img
							className={"tool-white-image"}
							alt=""
							src={LogoOutline}
						/>
					</div>
				</div>
				<div className="home-block padding-top-30 max-size-home content-item">
					<h2 className={"home-title"}>Nossos serviços</h2>
					<S.BoxContainer>
						<S.Box>
							<div className="box-content">
								<p className="box-title monta">Monta</p>
							</div>
							<div className="box-bg" />
							<img
								className="box-bg-img"
								src={Monta}
								alt="Background"
							/>
							<div
								className="animated-content"
								style={{
									backgroundColor: "#FE8504",
								}}
							>
								<ul>
									<li>Montagem de Móveis;</li>
									<li>Desmontagem de Móveis;</li>
									<li>Remanejamento de Móveis;</li>
									<li>Retorno de Montagem;</li>
									<li>Garantia de Montagem;</li>
									<li>Assistência Técnica.</li>
								</ul>
							</div>
						</S.Box>
						<S.Box>
							<div className="box-content">
								<p className="box-title">Instala</p>
							</div>
							<div className="box-bg" />
							<img
								className="box-bg-img"
								src={Instala}
								alt="Background"
							/>
							<div
								className="animated-content"
								style={{
									backgroundColor: "#1E6BB1",
								}}
							>
								<div style={{ overflow: "auto" }}>
									<ul>
										<li>Instalação de Ar Condicionado;</li>
										<li>
											Desinstalação de Ar Condicionado;
										</li>
										<li>
											Instalação de Ventilador de Teto;
										</li>
										<li>
											Instalação de Suporte e Configuração
											de Tv;
										</li>
										<li>
											Instalação de Home Theater e/ou
											Soundbar;
										</li>
										<li>
											Instalação de Lavadora de Roupas;
										</li>
										<li>
											Instalação de Secadora de Roupas;
										</li>
										<li>Instalação de Lava e Seca;</li>
										<li>Instalação de Lava Louças;</li>
										<li>
											Instalação de Forno Elétrico ou Gás;
										</li>
										<li>Instalação de Cooktop Elétrico;</li>
										<li>
											Instalação de Fogão ou Cooktop (Sem
											Kit);
										</li>
										<li>
											Instalação de Fogão ou Cooktop (Com
											Kit Glp);
										</li>
										<li>
											Instalação de Fogão ou Cooktop (Com
											Kit Gnv);
										</li>
										<li>
											Instalação de Fogão Comercial ou
											Industrial;
										</li>
										<li>Conversão de Fogão;</li>
										<li>Instalação de Coifa (Ilha);</li>
										<li>Instalação de Coifa (Parede);</li>
										<li>Instalação de Depurador;</li>
										<li>
											Instalação de Purificador de Água;
										</li>
										<li>
											Instalação de Refrigerador Até 500L;
										</li>
										<li>
											Instalação de Refrigerador Side By
											Side acima de 500L;
										</li>
										<li>
											Instalação de Refrigerador French
											Door (Com Torneira);
										</li>
										<li>
											Instalação de Refrigerador French
											Door (Sem Torneira);
										</li>
										<li>Instalação de Cama e Colchão;</li>
										<li>
											Instalação de Fechadura Eletrônica.
										</li>
										<br />
									</ul>
								</div>
							</div>
						</S.Box>
						<S.Box>
							<div className="box-content">
								<p className="box-title">Repara</p>
							</div>
							<div className="box-bg" />
							<img
								className="box-bg-img"
								src={Repara}
								alt="Background"
							/>

							<div
								className="animated-content"
								style={{
									backgroundColor: "#F86DFC",
								}}
							>
								<ul>
									<li>Conserto de Eletrodomésticos;</li>
									<li>Conserto de Eletrônicos</li>
									<li>Troca de Flexível;</li>
									<li>Inspeção de Estofados e Colchões;</li>
									<li>
										Assistência Técnica (Inspeção Presencial
										e/ou Virtual).
									</li>
								</ul>
							</div>
						</S.Box>
						<S.Box>
							<div className="box-content">
								<p className="box-title">Conserva</p>
							</div>
							<div className="box-bg" />
							<img
								className="box-bg-img"
								src={Conserva}
								alt="Background"
							/>

							<div
								className="animated-content"
								style={{
									backgroundColor: "#3AA64C",
								}}
							>
								<ul>
									<li>Limpeza de Ar Condicionado;</li>
									<li>Higienização de Ar Condicionado;</li>
									<li>Impermeabilização de Estofados;</li>
									<li>Impermeabilização de Colchões;</li>
									<li>Limpeza de Placa Solar.</li>
								</ul>
							</div>
						</S.Box>
					</S.BoxContainer>
				</div>
				<div className="partner-home-bg home-block partner-home-container max-size-home first-second-third-content content-item">
					<div className={"home-block-center"}>
						<h2 className={"home-title"}>
							Por que ter a MMS como parceiro?
						</h2>
						<CarouselProvider
							visibleSlides={qtdCarrossel}
							totalSlides={5}
							step={1}
							naturalSlideWidth={100}
							naturalSlideHeight={200}
						>
							{qtdCarrossel !== 5 ? (
								<ButtonBack>
									<KeyboardArrowLeftIcon />
								</ButtonBack>
							) : null}
							<Slider>
								<Slide index={0}>
									<div className={"carrousel-container"}>
										<div
											className={
												"carrousel-association-container"
											}
										>
											<img
												className={"carrousel-image"}
												alt={""}
												src={Association}
											/>
										</div>
										<div>
											<p className={"carrousel-text"}>
												Ampla experiência em gestão e
												curadoria de rede de parceiros
											</p>
										</div>
									</div>
								</Slide>
								<Slide index={1}>
									<div className={"carrousel-container"}>
										<div
											className={
												"carrousel-brain-container"
											}
										>
											<img
												className={"carrousel-image"}
												alt={""}
												src={Brain}
											/>
										</div>
										<div>
											<p className={"carrousel-text"}>
												Tecnologia no estado da arte e
												metodologia ágil para
												implantação de novos projetos
											</p>
										</div>
									</div>
								</Slide>
								<Slide index={2}>
									<div className={"carrousel-container"}>
										<div
											className={
												"carrousel-infrastructure-container"
											}
										>
											<img
												className={"carrousel-image"}
												alt={""}
												src={Infrastructure}
											/>
										</div>
										<div>
											<p className={"carrousel-text"}>
												Infraestrutura robusta e
												escalável
											</p>
										</div>
									</div>
								</Slide>
								<Slide index={3}>
									<div className={"carrousel-container"}>
										<div
											className={
												"carrousel-rate-container"
											}
										>
											<img
												className={"carrousel-image"}
												alt={""}
												src={Rated}
											/>
										</div>
										<div>
											<p className={"carrousel-text"}>
												Relação contratual baseada em
												performance
											</p>
										</div>
									</div>
								</Slide>
								<Slide index={4}>
									<div className={"carrousel-container"}>
										<div
											className={
												"carrousel-performance-container"
											}
										>
											<img
												className={"carrousel-image"}
												alt={""}
												src={Growth}
											/>
										</div>
										<div>
											<p className={"carrousel-text"}>
												Comprometimento com alto nível
												de qualidade
											</p>
										</div>
									</div>
								</Slide>
							</Slider>
							{qtdCarrossel !== 5 ? (
								<ButtonNext>
									<KeyboardArrowRightIcon />
								</ButtonNext>
							) : null}
						</CarouselProvider>
					</div>
				</div>

				<div className="vantage-home-bg  home-block avantage-container partner-home-container max-size-home first-second-third-content content-item">
					<div className="home-block-center max-size-home">
						<div className={"advantage-spacing"}>
							<h2 className={"home-title"}>
								VANTAGENS DO MODELO | MONTA
							</h2>
							<div className={"advantage-group-container"}>
								<div className={"advantage-block"}>
									<div>
										<h3 className={"advantage-title"}>
											Para o Varejista e para a MMS
										</h3>
									</div>
									<ul className={"advantage-list"}>
										<li className={"advantage-list-item"}>
											Foco ainda maior no Core Business
										</li>
										<li className={"advantage-list-item"}>
											Aumento da geração de eficiência
										</li>
										<li className={"advantage-list-item"}>
											Maior percepção de valor do serviço
										</li>
										<li className={"advantage-list-item"}>
											Criação e consolidação de um novo
											mercado
										</li>
										<li className={"advantage-list-item"}>
											&nbsp;
										</li>
									</ul>
								</div>

								<div className={"advantage-block"}>
									<div>
										<h3 className={"partner-title"}>
											Para o Parceiro Montador
										</h3>
									</div>
									<ul className={"partner-list"}>
										<li className={"partner-list-item"}>
											Segurança
										</li>
										<li className={"partner-list-item"}>
											Possibilidade da ampliação de
											serviços
										</li>
										<li className={"partner-list-item"}>
											Flexibilidade na agenda de serviços
										</li>
										<li className={"partner-list-item"}>
											Rota de trabalho inteligente
										</li>
										<li className={"partner-list-item"}>
											Pagamento quinzenal
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="home-block-flex experience-container max-size-home content-item">
					<div className={"experience-block"}>
						<h2 className={"experience-title"}>
							Ótima experiência
							<br /> para o cliente!
						</h2>
						<h3 className={"experience-subtitle"}>
							Desde a venda até realização do serviço:
						</h3>
						<ul className={"experience-list"}>
							<li className={"experience-list-item"}>
								<img
									className={
										"experience-list-image-communication"
									}
									alt=""
									src={Comments}
								/>
								Disponibilização de multicanais de comunicação
							</li>
							<li className={"experience-list-item"}>
								<img
									className={"experience-list-image-time"}
									alt=""
									src={Rapidez}
								/>
								Prazos curtos para agendamento da montagem
							</li>
							<li className={"experience-list-item"}>
								<img
									className={"experience-list-image-delivery"}
									alt=""
									src={Delivery}
								/>
								Compromisso com os prazos estabelecidos
							</li>
						</ul>
					</div>
				</div>

				<div
					className={
						"structure-home-bg home-block-flex structure-section"
					}
				>
					<div className="max-size-home fourth-content content-item">
						<h2 className={"structure-title"}>Nossa Estrutura</h2>
						<div>
							<div className={"structure-container"}>
								<div className={"fourth-content-items"}>
									<div className={"structure-group-item"}>
										<img
											className={"structure-icon-yellow"}
											src={Association}
											alt={""}
										/>
										<p className={"structure-group-text"}>
											Modelo das relações com respaldo de
											escritório jurídico renomado, com
											clientes que atuam em mercados
											similares
										</p>
									</div>
								</div>
								<div className={"fourth-content-items"}>
									<div className={"structure-group-item"}>
										<img
											className={"structure-icon-purple"}
											src={Cellphone}
											alt={""}
										/>
										<p className={"structure-group-text"}>
											Utilização de ferramenta
											mundialmente conhecida e testada
											para gestão de serviços de campo
										</p>
									</div>
								</div>
							</div>
							<div className={"structure-container"}>
								<div className={"fourth-content-items"}>
									<div className={"structure-group-item"}>
										<img
											className={"structure-icon-blue"}
											src={Training}
											alt={""}
										/>
										<p className={"structure-group-text"}>
											Ampla infraestrutura para
											treinamento e desenvolvimento dos
											parceiros
										</p>
									</div>
								</div>
								<div className={"fourth-content-items"}>
									<div className={"structure-group-item"}>
										<img
											className={"structure-icon-orange"}
											src={Group}
											alt={""}
										/>
										<p className={"structure-group-text"}>
											Time dedicado ao segmento e com
											grande experiência na área de
											atuação
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className={"structure-spacer"} />
					</div>
				</div>
				<div className={"our-tools"}>
					<h2 className={"tools-title home-title"}>
						Nossas Ferramentas
					</h2>
					<div className={"tools-container"}>
						<div
							className={
								"full-screen-width top-item fourth-content-item"
							}
						>
							<div className={"four-content-text"}>
								<div className={"full-screen-width-element"}>
									<h3
										className={
											"tools-title-left align-blocks-left"
										}
									>
										Para o Parceiro Montador
									</h3>
									<div
										className={
											"tools-image-block-montador align-blocks-left"
										}
									>
										<img
											className={"structure-icon-blue"}
											src={MontadorFerramenta}
											alt={""}
										/>
									</div>
									<p
										className={
											"tools-description-left align-blocks-left"
										}
									>
										Aceite dinâmico, otimização de roteiros,
										autonomia para a gestão de agenda, check
										list/laudo fotográfico (antes e depois),
										automação de atividades de suporte.
									</p>
								</div>
							</div>
						</div>

						<div
							className={
								"full-screen-width bottom-item fourth-content-item"
							}
						>
							<div className={"four-content-text"}>
								<div className={"full-screen-width-element"}>
									<h3
										className={
											"tools-title-right align-blocks-right"
										}
									>
										Para o Varejista
									</h3>
									<div
										className={
											"tools-image-block-varejista align-blocks-right"
										}
									>
										<img
											className={"structure-icon-orange"}
											src={Graph}
											alt={""}
										/>
									</div>

									<p
										className={
											"tools-description-right align-blocks-right"
										}
									>
										Ferramentas que auxiliam a macro e micro
										gestão adequada aos perfis específicos.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<ScrollToTop />
			<Footer />
		</section>
	);

	function resize() {
		if (window.innerWidth <= 700) {
			setQtdCarrossel(1);
		} else if (window.innerWidth <= 1000) {
			setQtdCarrossel(2);
		} else if (window.innerWidth <= 1170) {
			setQtdCarrossel(3);
		} else if (window.innerWidth <= 1260) {
			setQtdCarrossel(4);
		} else if (window.innerWidth <= 1400) {
			setQtdCarrossel(5);
		} else {
			setQtdCarrossel(5);
		}
	}
}

export default Second;
