import React, { useEffect } from "react";

import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Footer from "../../components/Footer/Footer";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { publicArea } from "../../../Routes.js";

function TermOfUse(props) {
	// Similar ao componentDidMount e componentDidUpdate:
	useEffect(() => {
		publicArea();
	}, []);

	return (
		<>
			<div className="terms-paragraph">
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/">
						Home
					</Link>
					<Typography color="textPrimary">
						Termos e Condições de Uso
					</Typography>
				</Breadcrumbs>
				<h1>TERMOS E CONDIÇÕES DE USO DO SITE MMS</h1>
				<p>
					Os Termos e Condições de Uso do site MMS (“Termo de Uso”),
					aplicam-se aos Usuários que utilizam a referida plataforma
					da{" "}
					<b>
						MMS Intermediação de Serviços e Negócios em Geral Ltda
					</b>
					, inscrita no CNPJ/MF sob o nº 34.592.703/0001-78, com sede
					na Rua Bonnard, nº 980, Edifício 19, sala 07, Condomínio
					Green Valley, Alphaville, Barueri-SP, CEP 06465-134
					(doravante designada “MMS”). Regula, ainda, o uso do Portal
					para cadastro de dados dos Usuários e formação de Banco de
					Dados, através do site{" "}
					<a href="https://mms.com.vc.">mms.com.vc</a>.
				</p>
				<p>
					Por este Termo de Uso, a MMS apresenta aos seus visitantes e
					Usuários em geral (todos em conjunto denominados “Usuários”)
					as condições essenciais para o uso do Portal.
				</p>
				<p>
					Ao navegar neste Portal, os Usuários declaram ter lido,
					compreendido, aceito e, com isso, se submetem às condições
					destes Termos de Uso que o regem, inclusive à Política de
					Privacidade e a Política de Cookies, bem como todos os
					outros alertas, documentos e avisos, que possam aparecer
					vinculados a assuntos tratados nos referidos Termos de Uso.
				</p>
				<h1>1. ACEITAÇÃO</h1>
				<p>
					Ao acessar o Portal, o Usuário deverá ler todo o conteúdo
					destes Termos de Uso e, se estiver de acordo com as
					condições apresentadas, manifestar o seu consentimento
					livre, expresso, informado e inequívoco, por meio da seleção
					do checkbox correspondente à opção: “Li e concordo com os
					Termos de Uso, Política de Privacidade e Política de
					Cookies”.
				</p>
				<p>
					O Usuário manifesta expressamente ter a capacidade legal
					para usar a Plataforma e para realizar o cadastro de
					informações para compor o Banco de Dados de potenciais
					parceiros da MMS.
				</p>
				<p>
					<strong>
						O Usuário declara ainda ter ciência de que pode sanar
						quaisquer dúvidas em relação a estes Termos de Uso pelos
						Canais de Comunicação especificados no item 16 deste
						documento.
					</strong>
				</p>
				<h1>INFORMAÇÕES GERAIS</h1>
				<h1>2. DEFINIÇÕES</h1>
				<ul>
					<li>
						<strong>MMS (“MMS”; “Nós”):</strong> prestadora de todos
						os Serviços objeto destes Termos de Uso e denominada
						como MMS Intermediação de Serviços e Negócios em Geral
						Ltda.
					</li>
					<li>
						<strong>
							Termos e Condições de Uso (“Termos de Uso”):
						</strong>{" "}
						o presente documento, em que estão descritas as
						condições que regulam a prestação dos Serviços
						oferecidos no Portal.
					</li>
					<li>
						<strong>Política de Privacidade:</strong> documento que
						informa como tratamos seus dados pessoais, para quais
						finalidades, com quem os compartilhamos, os direitos que
						o Usuário possui, dentre outros itens cabíveis. Clique
						aqui para saber mais:
						<a href="https://mms.com.vc/#/politica-de-privacidade">
							https://mms.com.vc/#/politica-de-privacidade
						</a>
						.
					</li>
					<li>
						<strong>Política de Cookies:</strong> documento que
						informa como a MMS utiliza Cookies. Clique aqui para
						saber mais.
						<a href="https://mms.com.vc/#/politica-de-cookies">
							https://mms.com.vc/#/politica-de-cookies
						</a>
						.
					</li>
					<li>
						<strong>Portal:</strong> meio pela qual a MMS presta os
						seus Serviços, através do site{" "}
						<a href="https://mms.com.vc">mms.com.vc</a>
					</li>
					<li>
						<strong>Serviços: </strong>todas as funcionalidades
						oferecidas pela MMS, consistentes na intermediação,
						através do nosso Portal, de montagem de móveis em todo o
						Brasil.
					</li>
					<li>
						<strong>Maior de idade:</strong>Pessoa física maior de
						dezoito (18) anos.
					</li>
					<li>
						<strong>Mensagens de dados:</strong> É toda forma de
						comunicação realizada, com a finalidade de prestar
						informações sobre produtos e serviços, atividades
						comerciais e comunicar estratégias ou campanhas
						publicitárias ou de marketing; realizada como mecanismo
						de referência e não como oferta pública.
					</li>
					<li>
						<strong>Janelas Emergentes</strong> (Pop-Ups): Janela ou
						aviso da internet que emerge automaticamente em qualquer
						momento quando o Portal é utilizado...
					</li>
					<li>
						<strong>Usuários (“Você”): </strong>todas as pessoas
						físicas ou jurídicas que visitam ou se cadastram no
						Portal e recebem uma Conta de Acesso individual e
						exclusiva;
					</li>
					<li>
						<strong>Parceiros: </strong>todas as pessoas jurídicas
						que se cadastram no Portal, fornecem os dados e
						informações da sua empresa à MMS, a fim de comporem um
						Banco de Dados de potenciais parceiros,{" "}
						<b>
							<u>
								sem que esse cadastro implique em vínculo,
								exclusividade ou garantia de negócios;
							</u>
						</b>
					</li>
					<li>
						<strong>Banco de Dados:</strong>conjunto de arquivos e
						relacionados entre si com registos de informações sobre
						o Usuário e sua empresa, que se relacionam de forma a
						compor informações e dar maior eficiência durante uma
						pesquisa ou estudos.
					</li>
					<li>
						<strong>Conta de Acesso:</strong> credencial digital
						formada pela combinação de login e senha de acesso,
						única e intransferível para cada Usuário, que permite a
						inclusão, atualização ou alteração de informações pelo
						Usuário, sobre os seus serviços, área de atuação e
						outras;
					</li>
					<li>
						<strong>Criptografia: </strong>nome dado ao processo de
						codificação de informações. As informações são
						codificadas (embaralhadas) na origem e decodificadas no
						destino, dificultando, dessa forma, que sejam decifradas
						durante o tráfego na internet.
					</li>
					<li>
						<strong>Firewall: </strong>nome dado ao dispositivo
						físico e/ou lógico de uma rede de computadores que tem
						por objetivo aplicar uma política de segurança a um
						determinado ponto de controle da rede. Sua função
						consiste em regular o tráfego de dados entre redes
						distintas e impedir a transmissão e/ou recepção de
						acessos nocivos ou não autorizados de uma rede para
						outra.
					</li>
					<li>
						<strong>Canal de Comunicação: </strong>meios colocados à
						disposição dos nossos Usuários para informações,
						sugestões, reclamações e/ou esclarecimentos acerca dos
						Serviços prestados pela MMS.
					</li>
					<li>
						<strong>Lei Brasileira:</strong>toda e qualquer
						legislação aplicável aos Serviços oferecidos pelo
						Portal, como, mas não limitado a: Constituição Federal
						de 1988; Código Civil (Lei 10.406/2002); Código de
						Defesa do Consumidor (Lei nº 8.078, de 11 de setembro de
						1990); Lei Geral de Proteção de Dados Pessoais (Lei nº
						13.709, de 14 de agosto de 2018), Marco Civil da
						Internet (Lei nº 12.965, de 23 de abril de 2014), dentre
						outras.
					</li>
				</ul>
				<h1>3. O PORTAL</h1>
				<p>
					Somos uma plataforma que integra clientes, lojas e
					montadores, com a finalidade de oferecer a melhor
					experiência em serviços, agregando o que há de mais
					sofisticado em tecnologia, com um atendimento de qualidade,
					pontualidade e excelência.
				</p>
				<p>
					O Portal tem por objetivo, além de apresentar à MMS aos
					visitantes e potenciais parceiros, permitir que o Usuário
					realize seu cadastro ou de sua empresa para a formação de
					Banco de Dados de potenciais parceiros, podendo tal
					cadastramento gerar ou não negócios ao Usuário,
					<b>
						<u>
							{" "}
							não havendo garantia de celebração de parceria,
							exclusividade, vínculo ou serviços.
						</u>
					</b>
				</p>
				<p>
					É extremamente importante que o Usuário tenha consciência
					que ao se cadastrar no Portal os seus dados e/ou de sua
					empresa irão compor um Banco de Dados, com informações sobre
					sua localização, atividades desenvolvidas/serviços, área de
					atuação entre outras, juntamente com informações de outros
					Usuários, sem que haja qualquer exclusividade, promessa ou
					expectativa de serviços, volume mínimo ou pagamento de
					quaisquer valores ou comissões.{" "}
				</p>
				<h1>4. QUEM PODE SE CADASTRAR NO SITE</h1>
				<p>
					Para realizar o cadastro no Portal MMS e fazer parte do
					Banco de Dados de potenciais parceiros da MMS são
					necessários alguns requisitos, ou seja, o Usuário precisa:
					(a) cadastrar-se e preencher as informações constantes no
					campo/formulário de cadastro disponível no site; (b) ter
					capacidade legal para contrair e assumir obrigações.
				</p>
				<p>
					É vedado ao Usuário o cadastro no Portal com outros dados
					que não sejam os seus. O Usuário que se cadastrar no Portal
					utilizando-se de dados de terceiros poderá incorrer em
					prática de crimes, sem prejuízo de eventual
					responsabilização em legisligação específica, além de sua
					exclusão do Portal{" "}
				</p>
				<p>
					O uso das contas no Portal é pessoal e intransferível, por
					isso os Usuários não estão autorizados a ceder os dados de
					validação para acessar ao Portal. No caso de esquecer os
					dados de validação ou usurpação deles, é obrigação do
					Usuário informar à MSS através da opção “esqueceu sua
					senha”.
				</p>
				<h1>5. CADASTRO, LOGIN E SENHA DE ACESSO</h1>
				<p>
					Para que o Usuário possa concluir o cadastro, o Usuário
					concorda em fornecer o seu nome completo, e-mail (opcional),
					número de telefone celular e CPF ou CNPJ, sendo encaminhado
					em seguida um convite para o preenchimento completo do
					Cadastro do Usuário, ocasião em que deverá preencher todos
					os campos obrigatórios com informações exatas e verdadeiras,
					responsabilizando-se, inclusive, em atualizar as informações
					de cadastro sempre que ocorrer alguma alteração. O Usuário
					responde legalmente pela veracidade e autenticidade das
					informações prestadas, isentando a MMS de qualquer
					responsabilidade neste sentido.
				</p>
				<p>
					Caso a MMS constate qualquer inexatidão nas informações
					prestadas pelo Usuário ou sobre sua empresa, bem com
					ilegalidade em sua conduta, poderá, a seu exclusivo
					critério, suspender ou cancelar a correspondente Conta de
					Acesso. Tal medida, contudo, não cessa nem diminui a
					responsabilidade do Usuário quanto à exatidão e veracidade
					dos dados que fornecer, tampouco em relação aos danos que
					possa causar a sua conduta.
				</p>
				<p>
					Ao preencher o cadastro, o Usuário indicará o seu CPF ou
					CNPJ, o qual será considerado <i>login</i> e senha de sua
					preferência.
				</p>
				<p>
					O cadastro do Usuário e sua senha são pessoais,
					intransferíveis e confidenciais, sendo vedado aos Usuários
					sua cessão, compartilhamento, venda ou qualquer outra forma
					de transferência, onerosa ou gratuita. A MMS poderá, a
					qualquer tempo, cancelar ou ainda recusar o cadastro de um
					Usuário, em casos de desconformidade com as regras e
					políticas destes Termos de Uso ou incorporadas a este por
					referência. Cadastro em duplicidade feitos por um mesmo
					Usuário também poderão ser cancelados.
				</p>
				<p>
					O Usuário será o único responsável por seu <i>login</i> e
					senha, respondendo por todos os atos praticados sob sua
					Conta de Acesso. Portanto, é dever do Usuário zelar pela
					guarda e confidencialidade de sua senha.
				</p>
				<p>
					O Usuário está ciente de que deverá comunicar a MMS, tão
					logo tenha ciência, em caso de extravio, roubo, perda,
					quebra de segurança ou qualquer uso não autorizado de sua
					Conta de Acesso, por meio do Canal de Comunicação indicado
					no item 16.
				</p>
				<ul>
					<li>
						<b>
							<u>Importante</u>: A MMS não solicita senha de
							acesso aos Usuários por e-mail ou telefone. Da mesma
							forma não solicita informações financeiras dos
							Usuários por e-mail ou telefone.
						</b>
					</li>
					<li>
						<b>
							<u>Importante</u>: A MMS poderá modificar
							autonomamente e em qualquer momento os aspectos
							formais, procedimentais ou substanciais destes
							Termos de Uso, que serão atualizados e colocados à
							disposição dos Usuários no Portal, em que Você,
							desde já, concorda e aceita que a última versão
							publicada é aquela que regulará as relações
							comerciais que sejam geradas no momento em que a
							transação for realizada. Caso sejam feitas
							alterações relevantes que necessitem de um novo
							consentimento dos Usuários, podemos colocar um aviso
							em nosso site ou te enviar um e-mail, SMS, WhatsApp,
							para obtermos o seu consentimento. Por isso é
							importante manter seus dados de contato atualizados.
						</b>
					</li>
				</ul>
				<h1>
					6. PRIVACIDADE DOS NOSSOS VISITANTES E USUÁRIOS E/OU
					CLIENTES
				</h1>
				<p>
					A MMS está comprometida com a privacidade e proteção de
					dados dos seus Usuários e Parceiros, utilizando as suas
					informações pessoais de acordo com a Lei Brasileira e
					adotando elevados padrões de segurança da informação.
				</p>
				<p>
					Para maiores informações, consulte os documentos: Política
					de Privacidade e Política de <i>Cookies</i>. Esses
					documentos constituem parte integrante e indissociável dos
					Termos de Uso do Portal MMS e deverá ser lido atentamente
					por todos os Usuários e Parceiros.
				</p>
				<h1>7. DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL</h1>
				<p>
					O uso comercial da expressão MMS como marca, nome
					empresarial ou nome de domínio, bem como os conteúdos das
					telas do Portal{" "}
					<a href="https://mms.com.vc">mms.com.vc</a>, assim
					como os programas, bancos de dados, redes, arquivos que
					permitem aos Usuários acessarem e utilizarem, não só por
					meio de sua Conta de Acesso, são de propriedade da MMS e
					estão protegidos pelas leis de direito autoral, marcas,
					patentes, modelos e desenhos industriais, sendo vedado o uso
					pelo Usuário, salvo mediante prévia autorização por escrito.
				</p>
				<p>
					Ao acessar a página da MMS, o Usuário declara que irá
					respeitar todos os direitos de propriedade intelectual e os
					decorrentes da proteção de marcas registradas da mesma, bem
					como de todos os direitos referentes a terceiros que
					porventura estejam, ou estiveram, de alguma forma,
					disponíveis no Portal. O simples acesso ao Portal não
					confere ao Usuário qualquer direito de uso dos nomes,
					títulos, palavras, frases, marcas, patentes, obras
					literárias, artísticas, lítero-musicais, imagens, vídeos,
					símbolos, entre outras, que nele estejam, ou estiveram,
					disponíveis.{" "}
					<b>
						A reprodução dos conteúdos descritos anteriormente está
						proibida, salvo mediante prévia autorização da MMS.
					</b>
					.
				</p>
				<p>
					É permitido fazer somente o arquivo temporário, sendo vedada
					sua utilização para finalidades comerciais, publicitárias ou
					qualquer outra que contrarie a realidade para o qual foi
					concebido, conforme definido nestes Termos de Uso. São
					igualmente proibidas a reprodução, distribuição, divulgação,
					adaptação, modificação, utilização, edição, disponibilização
					em qualquer meio de comunicação, etc., total ou parcial, dos
					textos, figuras, gráficos que compõem o presente Portal, sem
					prévia e expressa autorização da MMS, sendo permitida
					somente a impressão de cópias para uso e arquivo pessoal,
					sem que sejam separadas as partes que permitam dar o fiel e
					real entendimento de seu conteúdo e objetivo.
				</p>
				<p>
					Qualquer outro tipo de utilização de material autorizado,
					inclusive para fins editoriais, comerciais ou publicitários,
					só poderá ser feito mediante prévio e expresso consentimento
					da MMS. O Usuário assume toda e qualquer responsabilidade
					pela utilização indevida das informações, textos, gráficos,
					marcas, obras, imagens, vídeos, enfim, de todo e qualquer
					direito de propriedade intelectual ou industrial do Portal,
					tanto de caráter civil quanto criminal.
				</p>
				<p>
					A autorização para utilização do material solicitado não
					poderá ser transferida a terceiros, mesmo que vinculados ao
					Usuário por alguma razão, salvo se mediante prévia
					autorização por escrito da MMS. A utilização do material não
					autoriza o Usuário a expor terceiros ao ridículo, criar uma
					obra de caráter ilegal, caluniosa, difamatória, obscena ou
					imoral, que possa violar a ética, a moral e os bons
					costumes, sob pena de arcar com as penalidades aplicáveis
					pela Lei Brasileira.
				</p>
				<p>
					Qualquer utilização não contemplada na presente autorização
					será considerada como uma violação dos direitos de autor e
					sujeita às sanções cabíveis na Lei Brasileira, em especial a
					Lei n° 9.610, de 19 de fevereiro de 1998, que protege os
					direitos autorais no Brasil.
				</p>
				<p>
					A eventual retirada de qualquer artigo, vídeo, notícia ou
					fotografia reproduzido pelo Portal da MMS em decorrência de
					alguma reclamação, deverá ser sempre compreendida como uma
					demonstração de nossa intenção de evitar pendências com
					relação a este assunto e, jamais, como reconhecimento de
					qualquer infração a direito de terceiro ou de possuir culpa
					sobre o ocorrido.
				</p>
				<p>
					A eventual retirada de qualquer artigo, vídeo, notícia ou
					fotografia reproduzido pelo Portal da MMS em decorrência de
					alguma reclamação, deverá ser sempre compreendida como uma
					demonstração de nossa intenção de evitar pendências com
					relação a este assunto e, jamais, como reconhecimento de
					qualquer infração a direito de terceiro ou de possuir culpa
					sobre o ocorrido.
				</p>
				<p>
					<b>
						As fotos e imagens utilizadas no Portal podem não
						refletir seu tamanho original ou situação atual do
						cenário reproduzido, sendo meramente ilustrativas.
					</b>
				</p>
				<h1>8. RESPONSABILIDADE DO USUÁRIO</h1>
				<p>
					Com a aceitação destes termos o Usuário está obrigado a: (i)
					fornecer informações verazes e fidedignas no momento de
					criar sua Conta de Acesso; (ii) não transferir a terceiros
					os dados de validação (nome de Usuário e senha); (iii) não
					utilizar a plataforma para realizar atos contrários à moral,
					à ordem pública e os bons costumes; (iv) informar à MMS em
					caso de esquecimento, perda ou usurpação dos dados de
					validação; (v) não realizar condutas que atentem contra do
					funcionamento da plataforma; (vi) não suplantar a identidade
					de outros Usuários; (vii) não decifrar, desestruturar ou
					desmontar qualquer elemento da plataforma ou de qualquer uma
					das suas partes; (viii) habilitar a utilização de Janelas
					Emergentes durante a operação para o funcionamento adequado
					na plataforma; (ix) arcar com as obrigações de indenização e
					reparação por danos ocasionados em virtude da utilização
					indevida do Portal, excluindo-nos de prejuízos e ônus
					decorrentes de eventual ação judicial, arbitral ou processo
					administrativo; (x) cumprir com a Lei Brasileira.
				</p>
				<p>
					É de exclusiva responsabilidade e ônus do Usuário
					providenciar os equipamentos de informática e a conexão à
					Internet necessários para o acesso ao Portal, não se
					responsabilizando a MMS em nenhuma hipótese pela existência,
					funcionamento e qualidade de tais equipamentos, sistemas e
					conexões.
				</p>
				<p>
					Reservamos o direito de interromper o seu uso do Portal em
					decorrência da realização de práticas vedadas, que incluem
					as acima mencionadas, sem exclusão de outras aqui não
					previstas, mas que estejam em desacordo com a Lei
					Brasileira.
				</p>
				<h1>9. DEVERES DA MMS</h1>
				<p>
					Em razão destes termos, a MMS obriga-se a: (i) fornecer
					informações certas, fidedignas, suficientes, claras e
					atualizadas com respeito da empresa e de suas atividades;
					(ii) esclarecer quaisquer dúvidas dos Usuários em relação ao
					Portal, à MSS e suas atividades; (iii) colocar à disposição
					dos Usuários os Termos de Uso, Política de Privacidade e
					Política de Cookies, sempre de forma atualizada; (iv)
					utilizar as informações unicamente para os fins
					estabelecidos nestes termos e de acordo com a Política de
					Privacidade; (v) utilizar mecanismos de informações e
					validação durante a transação como as Janelas Emergentes
					(Pop Ups) que permitam ao Usuário aceitar ou não cada passo
					do processo de cadastro; (vi) cumprir com a Lei Brasileira.
				</p>
				<h1>10. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</h1>
				<p>
					Em decorrência de questões operacionais, de terceirização de
					serviços, o Portal pode estar sujeito a eventuais problemas
					de interrupção, falha técnica e indisponibilidade de
					funcionamento temporário. Quando for razoavelmente possível,
					a MMS advertirá previamente as interrupções do funcionamento
					de seu Portal aos seus Usuários.
				</p>
				<p>
					A MMS se exime de qualquer responsabilidade pelos danos e
					prejuízos de toda natureza que possam decorrer da falta de
					disponibilidade ou de continuidade do funcionamento do
					Portal.
				</p>
				<p>
					A MMS não se responsabiliza por qualquer dano, prejuízo ou
					perda no equipamento do Usuário causado por falhas no
					sistema, no servidor ou na conexão decorrentes de condutas
					de terceiros, incluindo ações de softwares maliciosos como
					vírus, cavalo de tróia, entre outros, que possam, de algum
					modo, danificar o equipamento ou conexão do Usuário em
					decorrência do acesso, utilização ou navegação no Portal,
					bem como a transferência de dados, arquivos, imagens, textos
					nele contidos.
				</p>
				<p>
					O uso dos Serviços ou do download por meio do Portal feito
					ao próprio critério e risco do Usuário e com o seu acordo de
					que será o único responsável por quaisquer danos ao seu
					sistema de computador ou perda de dados resultantes de tais
					atividades. Também não se pode imputar a MMS a
					responsabilidade em qualquer evento ocasionado por
					excludentes decorrentes de caso fortuitos ou motivo de força
					maior, que venham a causar danos a quaisquer das Partes.
				</p>
				<p>
					<b>
						A MMS não é responsável nem fornece garantia de que, por
						meio do mero cadastro do Usuário e geração de Conta de
						Acesso, ocorrerá celebração de parceria, ou vínculos de
						serviços. Os nossos Serviços são oferecidos sem qualquer
						exclusividade, promessa ou expectativa de serviços, bem
						como pagamento de valores ou comissões.{" "}
					</b>
				</p>
				<h1>11. FUNCIONAMENTO DA PLATAFORMA</h1>
				<p>
					 A MMS administra seu Portal diretamente ou por meio de
					terceiros. Dessa forma, a MMS não responderá por danos
					diretos ou indiretos sofridos pelo Usuário pela utilização
					ou incapacidade de utilização da Portal, inclusive por
					indisponibilidade sistêmica. A plataforma encontra-se
					disponível durante as 24 horas do dia para acesso e
					consulta.
				</p>
				<p>
					A MMS realiza os melhores esforços para manter o Portal em
					funcionamento, mas em nenhum caso garante a sua
					disponibilidade e continuidade permanente.
				</p>
				<p>
					A MMS reserva para si o direito de cancelar as Contas de
					Acesso de Usuários e/ou de proibir o acesso ao Portal dos
					Usuários que realizarem condutas que violem estes Termos de
					Uso ou que descumprirem as obrigações nestes Termos de Uso
					contraídas.
				</p>
				<h1>12. CANCELAMENTO E SUSPENSÃO DE CONTA</h1>
				<p>
					 A MMS  reserva para si o direito de cancelar, suspender,
					desativar, bloquear ou, encerrar unilateralmente o acesso
					do Usuário , com rescisão unilateral do presente Termos de
					Uso, com ou sem aviso prévio, caso: (i) seja identificada
					qualquer violação à Lei Brasileira; (ii) suas ações violem
					 às disposições destes Termos de Uso, ou alguma de suas
					Políticas; (iii) se o Usuário causar algum dano direto ou
					indireto à MMS, outros Usuários ou a terceiros; (iv) seja
					identificada, de forma direta ou indireta, a prática de
					atitudes fraudulentas; (v) outros casos em que a MMS entenda
					necessário, por violação da Lei Brasileira,
					incompatibilidade com a política da MMS, entre outras, a seu
					exclusivo critério.
				</p>
				<h1>13. LINKS DE TERCEIROS</h1>
				<p>
					No Portal, alguns Serviços disponíveis podem incluir
					materiais de terceiros. Dessa forma, os links direcionarão
					os Usuários para sites de terceiros que não são afiliados da
					MMS.
				</p>
				<p>
					Não somos responsáveis por quaisquer danos ou prejuízos
					relacionados com a utilização de Serviços, recursos,
					conteúdo, ou quaisquer outras interatividades ocorridas em
					conexão com quaisquer sites de terceiros.
				</p>
				<p>
					Por favor, revise com cuidado as políticas e práticas de
					terceiros e certifique-se de que Você as entenda antes de
					realizar qualquer interação. As queixas, reclamações,
					preocupações ou questões relativas a produtos de terceiros
					devem ser direcionadas ao terceiro.
				</p>
				<h1>14. DO USO DAS INFORMAÇÕES</h1>
				<p>
					As informações fornecidas pelos Usuários de forma livre e
					voluntária, serão armazenadas pela MMS para compor seu Banco
					de Dados de potenciais parceiros, sendo tratada e armazenada
					em conformidade com a Política de Privacidade e a Política
					de <i>Cookies</i>.
				</p>
				<p>
					A MMS é responsável pelo tratamento das informações pessoais
					levantadas através do site, responsabilidade que poderá ser
					delegada a um terceiro, na qualidade de responsável das
					informações, garantindo por meio de contrato o adequado
					tratamento das mesmas, conforme detalhado na Política de
					Privacidade.{" "}
				</p>
				<h1>15. DA LEGISLAÇÃO APLICÁVEL E FORO</h1>
				<p>
					Para dirimir quaisquer controvérsias oriundas do presente
					Instrumento, as partes elegem o Foro da Comarca de Barueri,
					no Estado de São Paulo (renunciando a qualquer outro por
					mais privilegiado que seja), sendo aplicáveis as Leis da
					República Federativa do Brasil.
				</p>
				<h1>16. CANAL DE COMUNICAÇÃO</h1>
				<p>
					Você poderá entrar em contato conosco, a qualquer tempo,
					para dúvidas, solicitações, reclamações, elogios e quaisquer
					outras demandas relacionadas ao Portal, por meio dos Canais
					indicados abaixo:
				</p>
				E-mail:{" "}
				<a href="mailto:contato@mms.com.vc">CONTATO@MMS.COM.VC</a>
				<div
					onClick={() => window.history.back()}
					className="terms-back-btn go-forward-button"
				>
					Voltar
				</div>
				<p>Atualizações:</p>
				<p>Criação: 01 de maio de 2020.</p>
				<p>Última Atualização: 27.01.2021</p>
			</div>
			<ScrollToTop />
			<Footer />
		</>
	);
}

export default TermOfUse;
