import React, { useEffect } from "react";

import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Footer from "../../components/Footer/Footer";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { publicArea } from "../../../Routes.js";

import "./style.css";

function CookiesTerms(props) {
	// Similar ao componentDidMount e componentDidUpdate:
	useEffect(() => {
		document.getElementById("background").style.display = "none";
		document.getElementById("quadrados").style.display = "none";
		document.getElementById("toolbar_container").style.position = "fixed";

		scrollToTop();
	}, []);

	return (
		<>
			<div className="terms-paragraph">
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/">
						Home
					</Link>
					<Typography color="textPrimary">
						Política de cookies
					</Typography>
				</Breadcrumbs>

				<h1>POLÍTICA DE COOKIES</h1>

				<p>
					Esta política de cookies tem como objetivo descrever as
					práticas de utilização dos cookies que seguimos para
					respeitar a privacidade de todos os Usuários do nosso site.
				</p>

				<p>
					Nosso site usa cookies para melhorar o desempenho e
					aprimorar a sua experiência de navegação. Certas áreas do
					Portal MMS também usam cookies para entender mais sobre
					Você, para que possamos oferecer uma experiência de
					navegação mais personalizada.
				</p>

				<p>
					Ao utilizar o nosso website e a permitir o uso de cookies, o
					Usuário consente com esta Política e com os Termos de Uso e
					a Política de Privacidade, que deverão ser lidas atentamente
					e em conjunto pelo Usuário.
				</p>

				<p>
					Caso não concorde com o nosso uso de cookies, deverá ajustar
					as definições do seu browser de acordo com as suas
					preferências. Caso desative os cookies que utilizamos, isso
					poderá ter impacto na sua experiência como Usuário enquanto
					permanecer no nosso site.
				</p>

				<h1>DEFINIÇÕES</h1>

				<ul>
					<li>
						<strong>MMS: (“MMS”; “Nós”):</strong> prestadora de
						todos os Serviços objeto desta Política e denominada
						como MMS Intermediação de Serviços e Negócios em Geral
						Ltda., inscrita no CNPJ/MF sob o nº 34.592.703/0001-78,
						com sede na Rua Bonnard, nº 980, Edifício 19, sala 07,
						Condomínio Green Valley, Alphaville, Barueri-SP, CEP
						06465-134.
					</li>
					<li>
						<strong>Política de Cookies (“Política”):</strong> o
						presente documento que informa como a MMS utiliza
						cookies.
					</li>
					<li>
						<strong>Portal MMS: </strong>meio pela qual a MMS presta
						os seus Serviços, através do website{" "}
						<a href="https://mms.com.vc">mms.com.vc</a>.
					</li>
					<li>
						<strong>Serviços:</strong> todas as funcionalidades
						oferecidas pela MMS, que consiste na intermediação,
						através do nosso Portal, de montagem de móveis em todo o
						Brasil.
					</li>
					<li>
						<strong>Dado Pessoal:</strong> toda informação que
						permite identificar uma pessoa natural, como nome, CPF,
						e-mail, etc.
					</li>
					<li>
						<strong>Usuários (“Você”):</strong> todas as pessoas
						físicas ou jurídicas que visitam ou se cadastram no
						Portal MMS e recebem uma conta de acesso individual e
						exclusiva;
					</li>
					<li>
						<strong>Canal de Comunicação:</strong> meios colocados à
						disposição dos nossos Usuários para informações,
						sugestões, reclamações e/ou esclarecimentos acerca dos
						Serviços prestados pela MMS.
					</li>
					<li>
						<strong>Lei Brasileira:</strong> toda e qualquer
						legislação aplicável aos Serviços oferecidos pelo Portal
						MMS, como, mas não limitado a: Constituição Federal de
						1988; Código Civil (Lei 10.406/2002); Código de Defesa
						do Consumidor (Lei nº 8.078, de 11 de setembro de 1990);
						Lei Geral de Proteção de Dados Pessoais (Lei nº 13.709,
						de 14 de agosto de 2018); Marco Civil da Internet (Lei
						nº 12.965, de 23 de abril de 2014), dentre outras.
					</li>
				</ul>

				<h1>O QUE É UM COOKIE?</h1>
				<p>
					Um cookie, pixel, arquivo web log, identificadores anônimos,
					imagens e outras tecnologias correlatas, são utilizadas para
					rastrear o uso e tendências, melhorar a qualidade do site,
					customizar sua experiência, e melhorar a administração de
					conteúdo. Um cookie é um arquivo minúsculo que reside em seu
					computador, celular ou outro aparelho, e que nos permite
					reconhecer Você como um Usuário. Você poderá regular o seu
					navegador para bloquear cookies, ou para indicar quando um
					cookie estiver sendo enviado por nós.
				</p>

				<p>
					Além disso, o cookie é atribuído individualmente e não pode
					ser usado para executar programas, tampouco infectar
					telefone móvel ou computadores com códigos maliciosos de
					qualquer espécie, tais como vírus, trojans, etc.
				</p>

				<h1>PARA QUAIS FINALIDADES UTILIZAMOS UM COOKIE</h1>

				<p>
					Utilizamos cookies para tornar o site da MMS de utilização
					mais fácil, para fornecer ao Usuário uma experiência
					personalizada enquanto explora o Portal MMS, e para uma
					melhor personalização dos nossos Serviços e site de acordo
					com os seus interesses e necessidades. Os cookies são
					utilizados para ajudar a agilizar as suas atividades futuras
					e a sua experiência em nosso site.
				</p>

				<p>
					Os cookies que Você autoriza são também utilizados para
					coletar os seus Dados Pessoais, que são posteriormente
					agrupados em perfis, para rastrear o uso e tendências,
					melhorar a qualidade do site, customizar sua experiência, e
					melhorar a administração de conteúdo.
				</p>

				<p>
					Também utilizamos cookies para compilar estatísticas
					anônimas e agregadas, que nos permitem compreender como as
					pessoas utilizam o nosso site, o que nos ajuda a melhorar as
					suas estruturas e conteúdo.
				</p>

				<p>Em resumo, utilizamos cookies para:</p>

				<ul>
					<li>Ajudar Você na navegação;</li>
					<li>
						Auxiliar no registro de nossos eventos, login e sua
						capacidade de fornecer feedback;
					</li>
					<li>
						Medir o uso do site (estatísticas e player de vídeo);
					</li>
					<li>Facilitar o compartilhamento em redes sociais;</li>
					<li>
						Ajudar com nossos esforços para fornecer conteúdo que
						seja mais relevante para Você e seus interesses.
					</li>
				</ul>

				<h1>COMO VOCÊ DESABILITA OS COOKIES?</h1>

				<p>
					O visitante pode desabilitar os cookies no seu navegador,
					mas esteja ciente de que, se Você desabilitar os "cookies",
					não poderá experimentar completamente a experiência do
					Portal MMS. Por exemplo, Você não poderá se beneficiar do
					login automático e de outros recursos de personalização do
					site.
				</p>

				<p>
					Se Você não deseja receber um cookie do nosso site, Você tem
					várias opções, como optar por não os receber ou desativando
					aqueles que teriam sido instalados no seu terminal. Você
					pode fazer a escolha a qualquer momento para expressar e
					modificar seus desejos com relação aos cookies.
				</p>

				<p>Por fim, em resumo, Você pode:</p>

				<ul>
					<li>
						personalizar suas preferências de cookies por meio de
						uma ferramenta de consentimento, que se abrirá no
						formato de um checkbox no Portal MMS;
					</li>
					<li>
						desativar os cookies MMS ou de terceiros utilizando as
						definições do seu browser/navegador.
					</li>
				</ul>

				<h1>CONTROLE ATRAVÉS DAS DEFINIÇÕES DO SEU BROWSER</h1>

				<p>
					A maioria dos navegadores é configurada por padrão e aceita
					a instalação de cookies. No entanto, Você tem a opção, se
					desejar, de aceitar todos os cookies ou optar por não os
					receber sistematicamente. Lembre-se de que Você não deve
					esquecer de configurar todos os navegadores de seus
					terminais diferentes (tablets, smartphones, computadores,
					etc.).
				</p>

				<p>
					Caso não queira que o nosso site guarde cookies no seu
					dispositivo, deverá alterar as definições do seu
					browser/navegador. Pode também retirar o seu consentimento
					relativo aos cookies apagando aqueles que estejam já
					guardados.
				</p>
				<p>
					Os procedimentos para alterar as suas definições e cookies
					diferem de browser para browser. Se necessário, utilize a
					função de ajuda do seu browser ou carregue em um dos
					seguintes links para ir diretamente para o manual do
					utilizador do seu browser.
				</p>
				<p>
					Em todo o caso, alertamos para o fato de que a desativação
					dos cookies que utilizamos pode causar impacto na sua
					experiência no Portal MMS.
				</p>

				<h1>QUE COOKIES USAMOS?</h1>

				<p>
					Os cookies utilizados pelo Portal MMS executam três funções,
					conforme abaixo:
				</p>

				<ul>
					<li>
						<strong>
							Cookies Essenciais/Estritamente Necessários
						</strong>
					</li>
					<li>
						<strong>Cookies de Desempenho</strong>
					</li>
					<li>
						<strong>
							Cookies FuncionaisOs cookies utilizados pelo Portal
							MMS executam três funções, conforme abaixo:
						</strong>
					</li>
				</ul>

				<h2>Cookies Essenciais/Estritamente Necessários</h2>

				<p>
					Alguns cookies que usamos são essenciais para o
					funcionamento do nosso site. Eles geralmente são definidos
					apenas em resposta a ações feitas por Você, o que equivale a
					uma solicitação, como definir suas preferências de
					privacidade, efetuar login ou preencher formulários. Você
					pode configurar seu navegador para bloqueá-los ou alertá-lo
					sobre esses cookies, mas algumas partes do site podem não
					funcionar.
				</p>

				<h2> Cookies de Desempenho</h2>

				<p>
					Alguns cookies nos ajudam com o desempenho e design do nosso
					site. Isso nos permite medir quantas vezes uma página foi
					visitada e saber quais páginas são as mais e menos populares
					e ver como os Usuários se movimentam pelo site. Todas as
					informações que esses cookies coletam são anônimas. Se Você
					não permitir esses cookies, não saberemos quando Você
					visitou nosso site. 
				</p>

				<h2>Cookies Funcionais</h2>
				<p>
					Alguns cookies nos ajudam a lembrar de suas configurações
					que Você pode ter selecionado, ou ajudam com outras
					funcionalidades quando Você navega e usa o site da MMS. Isso
					nos ajuda a lembrar o que Você selecionou, então, quando
					Você retorna, nos lembramos de suas preferências.
				</p>
				<p>
					Esses cookies permitem o fornecimento de funcionalidade e
					personalização aprimoradas. Eles podem ser definidos por nós
					ou por provedores terceiros cujos serviços adicionamos às
					nossas páginas. Se Você não permitir esses cookies, algumas
					ou todas essas funcionalidades podem não funcionar
					corretamente. 
				</p>

				<h1>ALTERAÇÕES A ESTA POLÍTICA DE COOKIES</h1>

				<p>
					A <strong>MMS</strong> pode alterar esta declaração de
					política de cookies conforme necessário. Caso sejam feitas
					alterações relevantes que necessitem de um novo
					consentimento dos Usuários, podemos colocar um aviso em
					nosso website ou te enviar um e-mail, SMS, WhatsApp,
					juntamente com esta Política atualizada, para obtermos o seu
					consentimento.
				</p>

				<h2>CANAL DE COMUNICAÇÃO</h2>

				<p>
					Se Você tiver dúvidas ou não achar que suas preocupações
					foram tratadas nesta Política, entre em contato enviando um
					e-mail para o seguinte endereço de e-mail:
					<a href={"mailto:contato@mms.com.vc"}>contato@mms.com.vc</a>
					.
				</p>
			</div>
			<ScrollToTop />
			<Footer />
		</>
	);

	function scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}
}

export default CookiesTerms;
