import React, { useEffect } from "react";

import Footer from "../../components/Footer/Footer";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import * as S from "./Styles";

import { publicArea } from "../../../Routes.js";

function PrivacyPolicy(props) {
	// Similar ao componentDidMount e componentDidUpdate:
	useEffect(() => {
		publicArea();
		scrollToTop();
	}, []);

	return (
		<>
			<div className="terms-paragraph">
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" href="/">
						Home
					</Link>
					<Typography color="textPrimary">
						Política de privacidade
					</Typography>
				</Breadcrumbs>
				<h1>POLÍTICA DE PRIVACIDADE DO SITE </h1>
				<S.Content>
					<p>
						A{" "}
						<b>
							MMS Intermediação de Serviços e Negócios em Geral
							Ltda.
						</b>{" "}
						(“MMS”), pessoa jurídica de direito privado, com sede na
						Rua Bonnard, nº 980, Edifício 19, Condomínio Green
						Valley, Alphaville, Barueri, estado de São Paulo, CEP
						06465-134, inscrita no CNPJ/MF sob o nº
						34.592.703/0001-78, doravante denominada simplesmente
						“MMS”, respeita a privacidade de todos que visitam este
						website ou seus aplicativos e está comprometida em
						manter a integridade e segurança das informações
						pessoais dos Usuários. A MMS preza pela comunicação
						transparente e acessível com seus Usuários, apresentando
						sua Política de Privacidade (“Política”), em
						conformidade com esses valores e com a Lei Brasileira.
					</p>
				</S.Content>
				<S.Content>
					<p>
						Ao visitar o website ou aplicativos da MMS e/ou submeter
						suas informações neste, os Usuários concordam com o teor
						dessa Política, inclusive com os dados já inseridos e
						fornecidos anteriormente.
					</p>
					<strong>Data da última atualização: 01/2024</strong>
				</S.Content>

				<S.Title>CLÁUSULA 1 – Definições</S.Title>
				<p>
					1.1. &nbsp; Esta Política será regida pelas definições dos
					termos em maiúsculo, listados abaixo. Caso haja algum termo
					não abordado nesta Política, a interpretação deverá ser de
					acordo com a Lei Brasileira:
				</p>
				<S.ListOl>
					<S.ListLi>
						<strong>Usuários (“Você”)</strong>: todas as pessoas
						naturais (pessoa física) que acessam o website ou
						aplicativos da MMS para buscas informações, contato,
						conteúdo, torna-se parceiro etc.
					</S.ListLi>

					<S.ListLi>
						<strong>Lei Brasileira</strong>: todas as normas
						jurídicas vigentes relacionadas à proteção de Dados
						Pessoais, incluindo, mas não se limitando à Constituição
						Federal Brasileira; Lei Geral de Proteção de Dados
						Pessoais (Lei nº 13.709/2018); Marco Civil da Internet
						(Lei nº 12.965/2014); Decreto Regulamentador do Marco
						Civil da Internet (Decreto nº 8.771/2016); Código Civil
						(Lei nº 10.406/2002), Código de Defesa do Consumidor
						(Lei n° 8.078/90); Lei de Acesso à Informação (Lei nº
						12.527/2011);
					</S.ListLi>

					<S.ListLi>
						<strong>
							Lei Geral de Proteção de Dados Pessoais - “LGPD”
						</strong>
						: dispõe sobre o Tratamento de Dados Pessoais, nos meios
						físicos e digitais, por pessoa natural (física) ou por
						pessoa jurídica de direito público ou privado;
					</S.ListLi>

					<S.ListLi>
						<strong>Dado Pessoal</strong>: informações relacionadas
						a uma pessoa natural (física), identificada ou
						identificável;
					</S.ListLi>

					<S.ListLi>
						<strong>Dado Pessoal Sensível</strong>: dados sobre
						origem racial ou étnica, convicção religiosa, opinião
						política, filiação à sindicato ou a organização de
						caráter religioso, filosófico ou político, dado
						referente à saúde ou à vida sexual, dado genético ou
						biométrico que, em conjunto com Dado Pessoal, serão
						denominados Dados Pessoais;
					</S.ListLi>

					<S.ListLi>
						<strong>Tratamento</strong>: toda operação realizada com
						Dados Pessoais, como as que se referem a coleta,
						produção, recepção, classificação, utilização, acesso,
						reprodução, transmissão, distribuição, processamento,
						arquivamento, armazenamento, eliminação, avaliação ou
						controle da informação, modificação, comunicação,
						transferência, difusão ou extração;
					</S.ListLi>

					<S.ListLi>
						<strong>Controlador</strong>: no âmbito desta Política,
						e no escopo do relacionamento com os Usuários, a MMS, a
						quem competem as decisões referentes ao Tratamento dos
						Dados Pessoais destes;
					</S.ListLi>

					<S.ListLi>
						<strong>Operador</strong>: pessoa natural (física) ou
						jurídica, de direito público ou privado, que realiza o
						Tratamento de Dados Pessoais em nome do Controlador;
					</S.ListLi>

					<S.ListLi>
						<strong>Titular dos Dados</strong>: no âmbito desta
						Política, os Usuários, pessoas naturais (físicas) a quem
						se referem os Dados Pessoais que são objeto de
						Tratamento;
					</S.ListLi>

					<S.ListLi>
						<strong>Encarregado de Proteção de Dados/DPO</strong>: é
						a pessoa responsável por para atuar como canal de
						comunicação entre o Controlador, os Titulares dos dados
						e a Autoridade Nacional de Proteção de Dados (ANPD),
						além de manter a conformidade da MMS com a Lei Geral de
						Proteção de Dados.
					</S.ListLi>

					<S.ListLi>
						<strong>Fale Conosco</strong>: é o canal de comunicação
						da MMS com seus Usuários, clientes, parceiros etc.;
					</S.ListLi>

					<S.ListLi>
						<strong>Canal de Denúncia</strong>: é o canal
						confidencial de denúncias da MMS, destinado para
						colaboradores, prestadores, fornecedores, cliente e
						outros;{" "}
						<a href="https://canalconfidencial.com.br/mms/">
							https://canalconfidencial.com.br/mms/
						</a>
					</S.ListLi>

					<S.ListLi>
						<strong>Trabalhe Conosco</strong>: canal que a MMS
						anuncia oportunidades de parceria e os Usuários possuem
						a possibilidade de candidatar-se ou realizarem
						indicações.
					</S.ListLi>
				</S.ListOl>

				<S.Title>CLÁUSULA 2 – Dados Coletados</S.Title>
				<p>
					2.1. &nbsp; A MMS não solicita Dados Pessoais para obter
					acesso à maior parte de seu website ou de seus aplicativos.
				</p>

				<p>
					2.2. &nbsp; Entretanto, a MMS poderá tratar os Dados
					Pessoais que são fornecidos voluntariamente pelos Usuários,
					através do
					<i> website</i> ou aplicativos, conforme os casos listados
					abaixo e os correspondentes Dados Pessoais:
				</p>

				<p>
					<b>&nbsp; 2.2.1. Seja Nosso Parceiro</b>
				</p>
				<p>
					Caso você queira ser nosso parceiro, você poderá encaminhar
					para a MMS, através do preenchimento do formulário em nosso
					website ou aplicativos, as seguintes informações: nome
					completo, telefone e e-mail. Após o preenchimento, a MMS
					analisará sua solicitação, entrando em contato o mais breve
					possível.
				</p>

				<p>
					<b>&nbsp; 2.2.2. Canal de Denúncia</b>
				</p>
				<p>
					Caso você precise relatar alguma irregularidade para a MMS,
					poderá acessar o nosso Canal de Denúncia, disponível em{" "}
					<a href="https://canalconfidencial.com.br/mms/">
						https://canalconfidencial.com.br/mms/
					</a>
					, Dessa forma, você preencherá um formulário, contendo as
					seguintes informações: tipo do relato; relacionamento do
					Usuário com a MMS; certeza ou suspeita dos fatos da
					denúncia; o que aconteceu; quem são as pessoas envolvidas;
					quando ocorreram os fatos; onde ocorreu; se os fatos
					continuam ocorrendo; outras informações; provas e/ou
					evidências da denúncia; com a possibilidade de anexar
					arquivos, documentos etc.
				</p>

				<p style={{ marginLeft: 16 }}>
					<b>
						&nbsp; 2.2.2.1. Considerando que alguns dos dados
						inseridos no Formulário do Canal de Denúncias podem se
						enquadrar como Dados Pessoais Sensíveis, o Usuário está
						ciente de que essas informações serão utilizadas para o
						fim específico de eventuais investigações e
						encaminhamento das denúncias, de acordo com a cláusula
						3, em conformidade com a Lei Brasileira e com as
						Políticas internas da MMS.
					</b>
				</p>

				<p>
					<b>&nbsp; 2.2.3. Fale conosco</b>
				</p>
				<p>
					Caso você precise entrar em contato conosco, por qualquer
					motivo, você poderá preencher um formulário no{" "}
					<i>website</i> ou aplicativos, com os seguintes Dados
					Pessoais: nome, e-mail, telefone e uma caixa de mensagem, em
					que Você poderá inserir as informações necessárias para que
					possamos solucionar e/ou encaminhar sua solicitação, dúvida,
					reclamação etc.
				</p>

				<p>
					<b>&nbsp; 2.2.4. Trabalhe conosco</b>
				</p>
				<p>
					Caso você possua interesse em trabalhar na MMS, poderá
					conferir as vagas que temos disponíveis no momento por meio
					do <i>website</i> ou aplicativos, e se candidatar para as de
					seu interesse. Além disso, caso não possua uma vaga
					compatível com o seu perfil, você poderá anexar o seu
					currículo no nosso banco de talentos.
				</p>

				<p>
					2.3. &nbsp; A MMS também trata <b>Dados de Acesso</b> dos
					Usuários, tais como, mas não limitados a: informações
					coletadas por meio da utilização do website ou aplicativos,
					como informações de tela e resolução; endereço do protocolo
					de internet (IP), páginas visitadas anteriormente e
					posteriormente ao acesso, MMS médio gasto, data e
					localização de acesso ao website ou aplicativos da MMS.
				</p>

				<p>
					2.4. &nbsp; Especificamente em relação aos dados de acesso
					coletados pela MMS, estes poderão ser obtidos através da
					utilização de <i>Cookies</i> e tecnologias semelhantes,
					conforme Cláusula 8. A implementação e acesso de{" "}
					<i>Cookies</i> se destina à melhoria da qualidade das
					informações fornecidas à MMS, tornando a experiência dos
					Usuários cada vez mais aprimoradas, de acordo com seus
					interesses e demandas.
				</p>

				<S.Title>
					CLÁUSULA 3 – Finalidades para Utilização dos Dados Pessoais
				</S.Title>
				<p>
					3.1. Os Dados Pessoais serão utilizados pela MMS para
					melhorar o atendimento aos Usuários, incluindo acesso ao
					conteúdo do <i>website</i> ou aplicativos e contato com a
					MMS, além de outras finalidades. Listamos abaixo as
					finalidades específicas:
				</p>

				<ul>
					<li>
						Análise e avaliação da solicitação para se tornar
						parceiro da MMS;
					</li>
					<li>
						Encaminhamento e investigações necessárias referentes ao
						Canal de Denúncias;
					</li>
					<li>
						Encaminhamento e análise do perfil dos Usuários, nos
						casos de interesse em trabalhar conosco;
					</li>
					<li>
						Contato com os Usuários, por meio do formulário
						preenchido no website, aplicativos e ou e-mail em que a
						MMS responderá as dúvidas, sugestões, reclamações e
						solicitações;
					</li>
					<li>
						Cumprimento de obrigações legais e/ou regulatórias;{" "}
					</li>
					<li>
						Análise do desempenho do website ou aplicativos da MMS,
						medição da audiência, verificação de hábitos de
						navegação, a forma pela qual o Usuário chegou à página,
						avaliação de estatísticas de acesso e uso do website ou
						aplicativos, seus recursos e funcionalidades;
					</li>
					<li>Realização de Auditorias Internas e Externas; </li>
					<li>
						Mapeamento de informações de mercado, estatísticas e
						elaboração de relatório de dados, para fins de melhorias
						no website ou nos aplicativos;
					</li>
					<li>
						Análises relacionadas à segurança do website ou
						aplicativos e à segurança da informação, prevenindo
						erros/falhas técnicas;
					</li>
					<li>Prevenção antifraude.</li>
				</ul>

				<p>
					3.2. A MMS não utiliza os Dados Pessoais dos Usuários para
					fins de marketing e propaganda.
				</p>

				<S.Title>
					CLÁUSULA 4 – Compartilhamento dos Dados Pessoais
				</S.Title>

				<p>
					4.1. Os Dados Pessoais dos Usuários poderão ser
					compartilhados de acordo com as finalidades descritas na
					cláusula 3. A MMS se compromete a fazê-lo apenas com
					empresas que empreguem nível de segurança da informação
					adequado, estabelecendo cláusulas contratuais que não violem
					as disposições dessa Política. Além disso, listamos abaixo
					as hipóteses de compartilhamento de suas informações:
				</p>

				<p>
					&nbsp; 4.1.1. Com empresas de consultoria e escritórios de
					advocacia, para proteção dos nossos interesses, incluindo
					casos de demandas judiciais, arbitrais e administrativas;
				</p>
				<p>
					&nbsp; 4.1.2. Com Prestadores de Serviços para o regular
					funcionamento das atividades da MMS;
				</p>
				<p>
					&nbsp; 4.1.3. Em caso de operações societárias, quando a
					transferência dos Dados Pessoais for necessária para a
					continuidade das atividades ofertadas no <i>website</i> ou
					aplicativos da MMS;
				</p>
				<p>
					&nbsp; 4.1.4. Mediante ordem judicial ou por requerimento de
					Autoridades Públicas ou Reguladoras que detenham competência
					para requisição;
				</p>
				<p>&nbsp; 4.1.5. Com empresas de auditorias;</p>
				<p>
					&nbsp; 4.1.6. Com órgãos e autoridades públicas, para fins
					de cumprimento de obrigações legais e/ou regulatórias;
				</p>
				<p>&nbsp; 4.1.7. Com empresas do grupo MMS.</p>

				<p>
					4.2. Considerando a natureza do <i>website</i> ou
					aplicativos, os Dados Pessoais dos Usuários poderão ser
					transferidos para outros países, para fins de armazenamento
					em nuvem, devido à localização dos servidores da MMS,
					situação com a qual o Usuário está ciente e concorda.
				</p>

				<S.Title>CLÁUSULA 5 – Proteção das Informações</S.Title>

				<p>
					5.1. A MMS se compromete a adotar todos os esforços
					razoáveis, considerando as soluções tecnológicas disponíveis
					e aplicáveis, para preservar a integridade e segurança dos
					Dados Pessoais dos Usuários.
				</p>

				<p>
					5.2. A MMS utiliza o princípio de <i>privacy by design</i>,
					o que significa que pensamos a privacidade previamente a
					realização das nossas atividades, respeitando e protegendo
					os Dados Pessoais em nossos procedimentos internos.
				</p>

				<p>
					5.3. A MMS adota padrões de segurança da informação
					adequados para a manutenção da confidencialidade e segurança
					dos seus Dados Pessoais. Apesar disso, não é possível
					garantir de forma absoluta a não ocorrência de erros no
					<i> website</i> ou aplicativos, incidentes de segurança,
					violações e acessos não autorizados, considerando que as
					práticas de segurança da internet encontram-se em constante
					evolução. Saliente-se, no entanto, que a MMS se compromete a
					acompanhar as evoluções e implementar melhorias de segurança
					constantemente, caso sejam possíveis e razoáveis.
				</p>

				<S.Title>CLÁUSULA 6 – Período de Retenção</S.Title>

				<p>
					6.1. A MMS armazenará os Dados Pessoais dos Usuários até
					atingir as finalidades descritas na Cláusula 3 e/ou para
					cumprimento de obrigações legais e regulatórias decorrentes
					das atividades disponibilizadas em nosso <i>website</i> ou
					nos nossos aplicativos.
				</p>

				<p>
					6.2. Os Usuários também poderão requerer a exclusão dos seus
					Dados entrando em contato conosco, por meio do endereço
					eletrônico{" "}
					<a href="mailto:dpo.privacidade@mms.com.vc">
						dpo.privacidade@mms.com.vc
					</a>
					. A MMS se compromete a empreender todos os esforços
					razoáveis para atender a esses pedidos, caso sejam cabíveis,
					no menor tempo possível e em cumprimento à Lei Brasileira.
				</p>
				<p>
					6.3. A MMS se reserva o direito de manter armazenado em seus
					servidores os Dados necessários ao cumprimento da Lei
					Brasileira, ainda que diante de requisição de exclusão pelo
					Usuário nos termos da LGPD.
				</p>

				<S.Title>CLÁUSULA 7 – Links de Terceiros</S.Title>

				<p>
					7.1. Para a sua conveniência, o <i>website</i> ou
					aplicativos da MMS pode conter links para outras páginas de
					empresas do Grupo MMS, bem como para sites externos.
				</p>
				<p>
					7.2. A MMS não se responsabiliza pelas políticas e práticas
					de privacidades de outros websites. Os Usuários se declaram
					cientes e concordam que a existência destes links não
					representa patrocínio a sites terceiros e reconhecem estarem
					sujeitos a documentação dessas empresas terceiras, que
					deverão ser verificadas pelos Usuários.
				</p>

				<S.Title>CLÁUSULA 8 – Cookies</S.Title>

				<p>
					8.1. <i>Cookies</i> são pequenos arquivos que podem ou não
					ser adicionados no seu dispositivo eletrônico, e que
					permitem armazenar e reconhecer dados de sua navegação. Em
					sua navegação no <i>website</i> ou aplicativos, poderão ser
					utilizados os tipos de Cookies:
				</p>

				<S.Table>
					<tr>
						<th>Tipos de Cookies</th>
						<th>Funcionalidades</th>
						<th>Espécies</th>
						<th>Período de Retenção</th>
					</tr>
					<tr>
						<td>
							<i>Cookies</i> de Autenticação
						</td>
						<td>
							Reconhecem um determinado Usuário, possibilitando o
							acesso a conteúdo e informações disponíveis no
							<i>website</i> ou aplicativos da MMS, proporcionando
							experiências de navegação mais personalizadas.
						</td>
						<td>-</td>
						<td>-</td>
					</tr>
					<tr>
						<td>
							<i>Cookies</i> de Segurança e Integridade
						</td>
						<td>
							Ativam recursos de segurança do <i>website</i> ou
							aplicativos para ajudar no monitoramento e/ou
							detecção de atividades maliciosas ou vedadas por
							esta Política, e para proteger as informações dos
							Usuários do acesso por terceiros não autorizados.
						</td>
						<td>-</td>
						<td>-</td>
					</tr>
					<tr>
						<td>
							<i>Cookies</i> de Pesquisa, Análise e Desempenho
						</td>
						<td>
							Ajudam a entender o desempenho do website ou
							aplicativos, medir a audiência, verificar hábitos de
							navegação, bem como a forma pela qual chegou na
							página da MMS (por exemplo, através de links de
							outros sites, buscadores ou diretamente pelo
							endereço).
						</td>
						<td>Google Analytics</td>
						<td>
							<a href="https://support.google.com/analytics/answer/7667196?hl=pt-BR">
								https://support.google.com/analytics/answer/7667196?hl=pt-BR
							</a>
						</td>
					</tr>
				</S.Table>

				<p>
					8.2. A MMS utiliza <i>cookies</i> para memorizar as
					informações dos Usuários quando estes utilizam o{" "}
					<i>website</i> ou aplicativos. O nosso objetivo é tornar sua
					experiência mais conveniente e personalizada.
				</p>

				<p>
					8.3. Além disso, a MMS também utiliza <i>cookies</i> para
					permitir que os Usuários utilizem o <i>website</i> ou
					aplicativos sem iniciar uma nova sessão, para preencher
					automaticamente formulários de contato, para realização de
					melhorias ao nosso <i>website</i> ou aplicativos e às
					necessidades dos nossos Usuários.
				</p>

				<p>
					8.4. Após o Usuário manifestar sua preferência para a
					utilização de <i>cookies</i>, quando da navegação no{" "}
					<i>website</i> ou aplicativos, a MMS armazenará um cookie em
					seu dispositivo para lembrar disso na próxima sessão.
				</p>

				<p>
					8.5. Os Usuários poderão personalizar suas preferências de
					<i> cookies</i> por meio de uma ferramenta de consentimento,
					que se abrirá no formato de um <i>checkbox</i> no website ou
					aplicativos da MMS, ou ainda diretamente no seu{" "}
					<i>browser</i>
					/navegador.
				</p>

				<p>
					8.6. Com exceção dos <i>cookies</i> necessários destinados a
					garantir o adequado funcionamento do <i>website</i> ou
					aplicativos e a segurança dos Usuários, que são estritamente
					necessários e não podem ser desativados do <i>website</i> ou
					aplicativos, os Usuários poderão desabilitar os demais tipos
					de <i>cookies</i>. Entretanto, os Usuários se declaram
					cientes de que é possível que a MMS não desempenhe as
					atividades disponíveis no <i>website</i> ou aplicativos de
					maneira satisfatória, devido à ausência de determinados{" "}
					<i>cookies</i>.
				</p>

				<p>
					8.7 Os procedimentos para alterar as suas definições e
					cookies diferem de navegador (browser) para navegador. Se
					necessário, utilize a função de ajuda do seu browser ou
					carregue em um dos seguintes links para ir diretamente para
					o manual do utilizador do seu browser:
					<S.CustomList>
						<li data-icon="✅ ">
							<a href="https://support.microsoft.com/pt-br/windows/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
								Internet Explorer
							</a>
						</li>
						<li data-icon="✅ ">
							<a href="https://support.mozilla.org/pt-BR/kb/limpe-cookies-e-dados-de-sites-no-firefox">
								Mozilla Firefox
							</a>
						</li>
						<li data-icon="✅ ">
							<a href="https://support.google.com/accounts/answer/61416?hl=pt-BR">
								Google Chrome
							</a>
						</li>
						<li data-icon="✅ ">
							<a href="https://support.apple.com/pt-br/guide/safari/sfri11471/16.1/mac/13.0">
								Safari
							</a>
						</li>
						<li data-icon="✅ ">
							<a href="https://help.opera.com/en/latest/web-preferences/">
								Opera
							</a>
						</li>
					</S.CustomList>
				</p>

				<S.Title>CLÁUSULA 9 – Direitos do Usuário</S.Title>
				<p>
					9.1. Os Usuários poderão solicitar à MMS a confirmação da
					existência de Tratamento de seus Dados Pessoais; o acesso
					aos seus Dados Pessoais; a correção de Dados que estejam
					incompletos ou desatualizados; a eliminação dos dados, entre
					outros direitos previstos na Lei Brasileira.
				</p>
				<p>
					9.2. Para que os Usuários possam exercer seus direitos,
					basta entrar em contato com o Encarregado de Proteção de
					Dados/DPO da MMS por meio do endereço eletrônico{" "}
					<a href="mailto:dpo.privacidade@mms.com.vc">
						dpo.privacidade@mms.com.vc
					</a>
					.
				</p>

				<p>
					9.3. A MMS se reserva o direito de utilizar meios de
					autenticação dos Usuários no momento de suas solicitações,
					como forma de segurança e proteção à integralidade das
					informações, evitando as chances de acessos aos Dados
					Pessoais por terceiros desautorizados e vazamentos ou roubos
					de dados.
				</p>

				<p>
					9.4. A MMS empreenderá seus melhores esforços para responder
					as solicitações dos Usuários no menor MMS possível e de
					forma completa, clara e de acordo com as suas legítimas
					expectativas.
				</p>

				<S.Title>CLÁUSULA 10 – Mudanças na Política</S.Title>

				<p>
					10.1. Considerando que a MMS está sempre buscando o
					aperfeiçoamento das suas atividades, esta Política pode
					passar por atualizações. Desta forma, recomendamos que os
					Usuários acessem periodicamente esta página.
				</p>
				<p>
					10.2. Caso sejam feitas alterações relevantes, podemos
					colocar um aviso em nosso <i>website</i> ou em nossos
					aplicativos juntamente com a Política de Privacidade
					atualizada.
				</p>
				<p>
					10.3. O Usuário desde já reconhece e aceita que, assim que
					publicada a alteração desta Política no <i>website</i> ou
					aplicativos, as atividades do <i>website</i> e dos
					aplicativos passarão a ser submetidas à Política atualizada.
				</p>

				<S.Title>
					CLÁUSULA 11 – Fale com o DPO da <b>MMS</b>.
				</S.Title>
				<p>
					12.1. A MMS nomeou Camila Schon como
					Encarregado de Proteção de Dados (DPO) e os Usuários poderão
					entrar em contato conosco, a qualquer tempo, para dúvidas,
					solicitações, reclamações e quaisquer outras demandas,
					através do endereço eletrônico{" "}
					<a href={"mailto:dpo.privacidade@mms.com.vc"}>
						dpo.privacidade@mms.com.vc
					</a>
				</p>
			</div>
			<ScrollToTop />
			<Footer />
		</>
	);

	function scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}
}

export default PrivacyPolicy;
