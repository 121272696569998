import React, { useLayoutEffect, useEffect, useState } from "react";
import Second from "../Second/Index";
import ScrollToBottom from "../../components/ScrolltoBottom/ScrollToBottom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CookiesModal from "../../components/CookiesModal/CookiesModal";

// Images
import HomeBG from "../../assets/images/home/home_1920x1080.png";

import { backPage, publicArea } from "../../../Routes.js";

import "./style_mms.css";

import Slide from "@material-ui/core/Slide";

const mobileHeight = window.innerHeight;

function First(props) {
	// Similar ao componentDidMount e componentDidUpdate:
	useEffect(() => {
		backPage(props);
		publicArea();
		localStorage.setItem("BackPage", "[]");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function useWindowSize() {
		const [size, setSize] = useState([0, 0]);
		useLayoutEffect(() => {
			function updateSize() {
				setSize([window.innerWidth, window.innerHeight]);
			}
			window.addEventListener("resize", updateSize);
			updateSize();
			return () => window.removeEventListener("resize", updateSize);
		}, []);
		return size;
	}

	function ShowWindowDimensions(props) {
		var [width, height] = useWindowSize();

		var windowHeight = "";
		var paddingTop = "";
		var scrollToBottom = 0;
		var maxWidth = width - 20;

		if (width > 991) {
			windowHeight = height - 112 + "px";
			scrollToBottom = height;
		} else {
			windowHeight = (height - 56) / 2 + "px";
			scrollToBottom = height;
			maxWidth = width;

			windowHeight = (mobileHeight - 56) / 2 + "px";
		}

		maxWidth += "px";

		if (props === "max-width") {
			const maxWidthStyle = {
				maxWidth: maxWidth,
			};

			return maxWidthStyle;
		}

		if (props === "height") {
			return scrollToBottom;
		}

		if (props === "padding") {
			const heightStyle = {
				paddingTop: paddingTop,
			};

			return heightStyle;
		}

		const heightStyle = {
			height: windowHeight,
		};

		return heightStyle;
	}

	return (
		<Slide direction="left" in={true} mountOnEnter unmountOnExit>
			<div>
				<section
					className="home-banner"
					style={ShowWindowDimensions("padding")}
				>
					<div className="home-content">
						<img
							className="home-img-bg"
							src={HomeBG}
							aria-hidden="true"
							alt=""
						/>
						<div className="banner-title">
							<h1 style={ShowWindowDimensions("max-width")}>
								Muito prazer, somos a
								<br />
								<strong>MMS</strong> - Muito Mais Serviços!
							</h1>

							<div className="home-buttons">
								<ScrollToBottom
									height={ShowWindowDimensions("height")}
								/>
								<a
									className={"both-buttons"}
									href="/#/seja-um-parceiro"
								>
									Quero ser parceiro
								</a>
							</div>
						</div>
					</div>
					<div className={"more-info-container"}>
						<span className={"more-info-text"}>Saiba mais</span>
						<ExpandMoreIcon />
					</div>
				</section>
				{/* Modal Promoção Covid */}
				{/* <CovidModal /> */}
				<CookiesModal />
				<Second />
			</div>
		</Slide>
	);
}

export default First;
