import styled from "styled-components";

export const ListOl = styled.ol`
	list-style-type: none;
	counter-reset: itemCounter;
`;
export const ListLi = styled.li`
	margin-bottom: inherit;
	::before {
		content: counter(itemCounter, upper-roman) ". ";
		counter-increment: itemCounter;
	}
`;
export const Title = styled.h1`
	color: #154869 !important;
	text-decoration: underline;
`;

export const Content = styled.div`
	border: 1px solid #000;
	padding: 10px;
	margin-bottom: 10px;
`;

export const Table = styled.table`
	border-collapse: collapse;

	table,
	td,
	th,
	tr {
		border: 1px solid black;
		padding: 10px;
	}

	th {
		color: #15125c;
	}

	td {
		text-align: center;
		&:nth-child(2) {
			width: 45%;
		}
		&:nth-child(1) {
			width: 30%;
			color: #15125c;
		}
	}
`;

export const CustomList = styled.ul`
	li {
		list-style: none;
	}

	li::before {
		content: attr(data-icon);
		font-size: 1.25em;
	}
`;
