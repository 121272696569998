import React from 'react';
import Routes from './Routes';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import './app/assets/style.css';

import { Provider } from 'react-redux'
import store from './app/store/user/Index.js'

function App() {
    return (
        <>
		<GoogleReCaptchaProvider 
			reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
		>
			<Provider store={store} >
                <Routes />
            </Provider>
		</GoogleReCaptchaProvider>
        </>
    );
}

export default App;
