import React, { useEffect, useState } from "react";
import {
	HashRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import Toolbar from "./app/components/toolbar/Toolbar";
import Loader from "./app/components/loader/Loader";

//Paginas
import PreCad from "./app/pages/PreCad/Index";
import PreCadMessage from "./app/pages/PreCad/Message";
import FirstAccess from "./app/pages/FirstAccess/Index";
import Welcome from "./app/pages/Welcome/Index";
import Login from "./app/pages/Login/Index";
import Reset from "./app/pages/Login/Reset";
import MessagePassword from "./app/pages/Login/Message";
import ResetPassword from "./app/pages/ResetPassword/Index";
import Home from "./app/pages/Home/Index";
import Error from "./app/pages/Error/Index";
import NotFound from "./app/pages/NotFound/Index";
import Progress from "./app/pages/Progress/Index";
import First from "./app/pages/First/Index";
import Third from "./app/pages/Third/Index";
import Indication from "./app/pages/Indication/Index";
import VideoGallery from "./app/pages/VideoGallery/Index";
import VideoToa from "./app/pages/VideoToa/Index";
import VideoCovid from "./app/pages/VideoCovid/Index";
import NoticesHub from "./app/pages/NoticesHub/Index";
import Faq from "./app/pages/Faq/Index";
import Notices from "./app/pages/Notices/Index";
import Notice from "./app/pages/Notices/Detail";
import CookiesTerms from "./app/pages/CookiesTerms/Index";
import PrivacyPolicy from "./app/pages/PrivacyPolicy/Index";
import TermOfUse from "./app/pages/TermOfUse/Index";
import Trails from "./app/pages/Ead/Trails";
import Trail from "./app/pages/Ead/Trail";
import Content from "./app/pages/Ead/Content";
import Simulator from "./app/pages/Ead/Simulator";
import AcceptContract from "./app/pages/AcceptContract/Index";
import CompleteMenu from "./app/pages/CompleteMenu/Index";
import InvestInYou from "./app/pages/InvestInYou/Index";
import ServingCustumer from "./app/pages/ServingCustomer/Index";

import quadrados from "./app/assets/images/quadrados.png";

//Controllers
import { AuthController } from "./app/controllers/UserController";

function Routes(props) {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		checkAuth();

		var hidden, visibilityChange;
		if (typeof document.hidden !== "undefined") {
			hidden = "hidden";
			visibilityChange = "visibilitychange";
		} else if (typeof document.mozHidden !== "undefined") {
			hidden = "mozHidden";
			visibilityChange = "mozvisibilitychange";
		} else if (typeof document.msHidden !== "undefined") {
			hidden = "msHidden";
			visibilityChange = "msvisibilitychange";
		} else if (typeof document.webkitHidden !== "undefined") {
			hidden = "webkitHidden";
			visibilityChange = "webkitvisibilitychange";
		}

		function handleVisibilityChange() {
			var element = document.activeElement;
			element.blur();
			element.focus();
		}

		document.addEventListener(
			visibilityChange,
			handleVisibilityChange,
			false
		);
	}, []);

	const PrivateRoute = ({ component: Component, ...rest }) => (
		<Route
			{...rest}
			render={(props) =>
				localStorage.getItem("DadosUsuario") !== null ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: { from: props.location },
						}}
					/>
				)
			}
		/>
	);

	return (
		<>
			{loading ? (
				<></>
			) : (
				<Router style={{ height: "100%" }}>
					<Toolbar />
					<Switch>
						{/* paginas */}
						<Route
							path="/pre-cadastro"
							component={(props) => <PreCad {...props} />}
						/>
						<Route
							path="/pre-cadastro-mensagem"
							component={(props) => <PreCadMessage {...props} />}
						/>

						<Route
							path="/primeiro-acesso"
							component={(props) => <FirstAccess {...props} />}
						/>

						<Route
							path="/bem-vindo"
							component={(props) => <Welcome {...props} />}
						/>
						<Route
							path="/login"
							component={(props) => <Login {...props} />}
						/>
						<Route
							path="/reset-senha"
							component={(props) => <Reset {...props} />}
						/>
						<Route
							path="/mensagem-senha"
							component={(props) => (
								<MessagePassword {...props} />
							)}
						/>
						<Route
							path="/nova-senha"
							component={(props) => <ResetPassword {...props} />}
						/>

						<Route
							path="/home"
							component={(props) => <Home {...props} />}
						/>

						<Route
							path="/indicacao"
							component={(props) => <Indication {...props} />}
						/>

						<Route
							path="/progresso"
							component={(props) => <Progress {...props} />}
						/>

						<Route
							exact
							path="/"
							component={(props) => <First {...props} />}
						/>

						<Route
							path="/seja-um-parceiro"
							component={(props) => <Third {...props} />}
						/>

						<Route
							path="/galeria-de-video"
							component={(props) => <VideoGallery {...props} />}
						/>

						<Route
							path="/videos-sobre-o-toa"
							component={(props) => <VideoToa {...props} />}
						/>

						<Route
							path="/previna-se-contra-o-coronavirus"
							component={(props) => <VideoCovid {...props} />}
						/>

						<Route
							path="/central-de-noticias"
							component={(props) => <NoticesHub {...props} />}
						/>

						<Route
							path="/faq"
							component={(props) => <Faq {...props} />}
						/>

						<Route
							path="/noticias"
							component={(props) => <Notices {...props} />}
						/>

						<Route
							path="/noticia/:id"
							component={(props) => <Notice {...props} />}
						/>

						<Route
							path="/ead/trilha/:tid/conteudo/:id"
							component={(props) => <Content {...props} />}
						/>

						<Route
							path="/invista-em-voce"
							component={(props) => <InvestInYou {...props} />}
						/>

						<Route
							path="/ead/trilhas"
							component={(props) => <Trails {...props} />}
						/>

						<Route
							path="/ead/trilha/:id"
							component={(props) => <Trail {...props} />}
						/>

						<Route
							path="/ead/simulador/:id/trilha/:tid/conteudo/:cid/pontoinicio/:pontoinicio"
							component={(props) => <Simulator {...props} />}
						/>

						<Route
							path="/politica-de-cookies"
							component={(props) => <CookiesTerms {...props} />}
						/>

						<Route
							path="/politica-de-privacidade"
							component={(props) => <PrivacyPolicy {...props} />}
						/>

						<Route
							path="/termo-de-uso"
							component={(props) => <TermOfUse {...props} />}
						/>

						<Route
							path="/contrato-de-intermediacao"
							component={(props) => <AcceptContract {...props} />}
						/>

						<Route
							path="/menu"
							component={(props) => <CompleteMenu {...props} />}
						/>

						<Route
							path="/erro"
							component={(props) => <Error {...props} />}
						/>
						{/* Pagina não encontrada */}
						<Route component={(props) => <NotFound {...props} />} />
					</Switch>
				</Router>
			)}
			<img
				id={"quadrados"}
				className="quadrados"
				src={quadrados}
				alt=""
				style={{ display: "none" }}
			/>
		</>
	);

	async function checkAuth() {
		if (localStorage.getItem("ApiToken") === null) {
			const response = await AuthController();
			response === "200" ? setLoading(false) : console.log(response);
		} else {
			setLoading(false);
		}
	}
}

export function backPage(reference) {
	if (localStorage.getItem("ActualPage") !== reference.location.pathname) {
		if (localStorage.getItem("BackPage") != null) {
			let historic = JSON.parse(localStorage.getItem("BackPage"));
			if (historic[historic.length - 1] !== reference.location.pathname) {
				historic.push(localStorage.getItem("ActualPage"));
				localStorage.setItem("BackPage", JSON.stringify(historic));
			} else {
			}
		} else {
			let historic = [];
			historic.push("/");
			localStorage.setItem("BackPage", JSON.stringify(historic));
		}
		localStorage.setItem("ActualPage", reference.location.pathname);
	}
}

export function publicArea() {
	document.getElementById("background").style.display = "none";
	document.getElementById("quadrados").style.display = "none";
	document.getElementById("toolbar_container").style.position = "fixed";
	document.getElementById("toolbar_container").style.width = "100%";
}

export function LoguedArea() {
	document.getElementById("background").style.backgroundColor = "#2e2b84";
	document.getElementById("background").style.display = "block";
	document.getElementById("quadrados").style.display = "block";
	document.getElementById("toolbar_container").style.position = "relative";
	document.getElementById("toolbar_container").style.width = "auto";
}

export default Routes;
